import { motion, useAnimation } from "framer-motion";
import { FileUp, FileText, Bot, Download } from "lucide-react";
import { useState, useEffect } from "react";

export default function HowItWorksSection() {
    const [step, setStep] = useState(0);
    const progressControls = useAnimation();

    const TOP_SKILLS = ["Python", "JavaScript", "Docker"];
    const JOB_POSITIONS = "Software Engineers";

    const steps = [
        {
            icon: FileUp,
            iconText: "Upload CV",
            title: "Upload Your CV or Start from Scratch",
            description:
                "Got a CV? Upload it. Starting from zero? Use our intuitive builder to create one from scratch.",
            color: "text-pink-500",
            inactiveColor: "text-pink-300",
        },
        {
            icon: FileText,
            iconText: "Paste Job Description",
            title: "Paste the Job Description You're Targeting",
            description: `TailoredCV.ai's AI analyzes the job description in seconds—extracting essential keywords and relevant skills like ${TOP_SKILLS.join(", ")}.`,
            color: "text-purple-500",
            inactiveColor: "text-purple-300",
        },
        {
            icon: Bot,
            iconText: "AI Tailoring",
            title: "Tailor Your CV Instantly",
            description:
                "Watch as our AI rewrites your CV, making it perfectly aligned with the job posting.",
            color: "text-blue-500",
            inactiveColor: "text-blue-300",
        },
        {
            icon: Download,
            iconText: "Tailored CV",
            title: "Download Your Optimized CV as a PDF",
            description: `Pick from professional templates designed for ${JOB_POSITIONS} and apply with confidence.`,
            color: "text-green-500",
            inactiveColor: "text-green-300",
        },
    ];

    useEffect(() => {
        const animationDuration = 12000; // 12 seconds for full cycle
        const stepDuration = animationDuration / 4; // 3 seconds per step

        const animate = async () => {
            // Step 1: Upload CV (0-25%)
            setStep(0);
            await progressControls.start({
                width: "25%",
                transition: { duration: stepDuration / 1000, ease: "linear" },
            });

            // Step 2: Paste Job Description (25-50%)
            setStep(1);
            await progressControls.start({
                width: "50%",
                transition: { duration: stepDuration / 1000, ease: "linear" },
            });

            // Step 3: AI Tailoring (50-75%)
            setStep(2);
            await progressControls.start({
                width: "75%",
                transition: { duration: stepDuration / 1000, ease: "linear" },
            });

            // Step 4: Download (75-100%)
            setStep(3);
            await progressControls.start({
                width: "100%",
                transition: { duration: stepDuration / 1000, ease: "linear" },
            });
        };

        const intervalId = setInterval(() => {
            setStep(0);
            progressControls.set({ width: "0%" });
            animate();
        }, animationDuration);

        animate();

        return () => clearInterval(intervalId);
    }, [progressControls]);

    return (
        <section className="w-full" id="how-it-works">
            <div className="container px-4 md:px-6">
                <div className="flex flex-col items-center space-y-4 text-center mb-12">
                    <div className="space-y-2">
                        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                            How It Works
                        </h2>
                        <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                            Create a tailored CV in minutes with our AI-powered platform
                        </p>
                    </div>
                </div>

                <div className="mb-16">
                    <div className="w-full h-96 bg-white flex items-center justify-center p-4 md:p-8">
                        <div className="w-full max-w-3xl bg-gray-200 p-1 rounded-lg shadow-lg">
                            <div className="bg-white w-full h-full rounded-lg p-4 md:p-6">
                                <div className="flex justify-between items-center relative mb-8">
                                    {steps.map(
                                        ({ icon: Icon, iconText, color, inactiveColor }, index) => (
                                            <div
                                                key={index}
                                                className="flex flex-col items-center z-10"
                                            >
                                                <motion.div
                                                    animate={{
                                                        scale: step === index ? 1.2 : 1,
                                                        opacity: step === index ? 1 : 0.5,
                                                    }}
                                                    transition={{ duration: 0.3 }}
                                                >
                                                    <Icon
                                                        className={`w-6 h-6 md:w-8 md:h-8 mb-2 ${step === index ? color : inactiveColor}`}
                                                        strokeWidth={2}
                                                    />
                                                </motion.div>
                                                <p
                                                    className={`text-xs md:text-sm text-center w-16 md:w-24 font-semibold ${step === index ? color : inactiveColor}`}
                                                >
                                                    {iconText}
                                                </p>
                                            </div>
                                        ),
                                    )}
                                </div>
                                <div className="relative h-1 bg-gray-200 rounded-full overflow-hidden mb-8">
                                    <motion.div
                                        className="absolute top-0 left-0 h-full bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500"
                                        animate={progressControls}
                                    />
                                </div>
                                <motion.div
                                    className="p-4 bg-gray-100 rounded-lg shadow-md"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <p className="text-gray-800 text-center text-sm md:text-base font-medium">
                                        {step === 0 && "Upload your existing CV to get started."}
                                        {step === 1 &&
                                            "Paste the job description you're applying for."}
                                        {step === 2 &&
                                            "Our AI analyzes and tailors your CV to the job."}
                                        {step === 3 &&
                                            "Download your perfectly tailored CV and apply!"}
                                    </p>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
                    {steps.map(({ title, description }, index) => (
                        <motion.div
                            key={index}
                            className="space-y-2"
                            animate={{
                                opacity: step === index ? 1 : 0.5,
                                scale: step === index ? 1 : 0.98,
                            }}
                            transition={{ duration: 0.3 }}
                        >
                            <div
                                className={`inline-block rounded-lg px-3 py-1 text-sm text-white transition-colors duration-300 ${
                                    step === index
                                        ? "bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700"
                                        : "bg-gray-400"
                                }`}
                            >
                                Step {index + 1}
                            </div>
                            <h3
                                className={`text-xl font-bold transition-colors duration-300 ${
                                    step === index ? "text-gray-900" : "text-gray-400"
                                }`}
                            >
                                {title}
                            </h3>
                            <p
                                className={`transition-colors duration-300 ${
                                    step === index ? "text-gray-500" : "text-gray-400"
                                }`}
                            >
                                {description}
                            </p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
}
