import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Link } from "@remix-run/react";
import posthog from "posthog-js";

function cookieConsentGiven() {
    const consent = localStorage.getItem("cookie_consent");
    if (!consent) {
        return "undecided";
    }
    return consent;
}

export default function CookieConsent() {
    const [consentGiven, setConsentGiven] = useState("");

    useEffect(() => {
        setConsentGiven(cookieConsentGiven());
    }, []);

    useEffect(() => {
        if (consentGiven !== "") {
            posthog.set_config({
                persistence: consentGiven === "yes" ? "localStorage+cookie" : "memory",
            });
        }
    }, [consentGiven]);

    const acknowledgeNotice = (acceptAnalytics: boolean) => {
        localStorage.setItem("cookie_consent", acceptAnalytics ? "yes" : "no");
        setConsentGiven(acceptAnalytics ? "yes" : "no");
    };

    if (consentGiven !== "undecided") return null;

    return (
        <Card className="fixed bottom-4 left-4 max-w-xs z-50 shadow-lg">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-bold">Cookie Notice</CardTitle>
            </CardHeader>
            <CardContent>
                <p className="text-xs text-muted-foreground mb-2">
                    At TailoredCV, we're committed to protecting your privacy. We use functional
                    cookies that are essential for the proper functioning of our services.
                </p>
                <p className="text-xs text-muted-foreground mb-2">
                    We also use tracking cookies to help us improve our product.
                </p>
                <p className="text-xs text-muted-foreground mb-2">
                    We do not use any marketing cookies, ensuring your browsing experience remains
                    private.
                </p>
                <p className="text-xs text-muted-foreground">
                    For more information, please read our{" "}
                    <Link
                        to={{
                            pathname: "/legal/privacy-policy",
                            hash: "9-cookies-and-their-use",
                        }}
                        className="text-xs text-primary hover:underline"
                    >
                        Privacy Policy
                    </Link>
                </p>
            </CardContent>
            <CardFooter className="flex justify-end space-x-2">
                <Button size="sm" onClick={() => acknowledgeNotice(false)} variant="outline">
                    Decline
                </Button>
                <Button size="sm" onClick={() => acknowledgeNotice(true)}>
                    Accept
                </Button>
            </CardFooter>
        </Card>
    );
}
